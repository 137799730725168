import React, { useState, useEffect } from "react"
import PhotoUpload from './PhotoUpload'
import { Input, Button, Loading, Grid, Select } from '@geist-ui/react'



export default function AddFighter (props) {

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(props.update && props.update.name)
  const [photo, setPhoto] = useState(props.update && props.update.photo)
  const [country, setCountry] = useState(props.update && props.update.country)
  const [sport, setSport] = useState(props.update && props.update.sport)
  const [gender, setGender] = useState(props.update && props.update.gender)
  const [birthdate, setBirthdate] = useState(props.update && props.update.birthdate)
  const [height, setHeight] = useState(props.update && props.update.height)
  const [reach, setReach] = useState(props.update && props.update.reach)
  const [wins, setWins] = useState(props.update && props.update.wins)
  const [losses, setLosses] = useState(props.update && props.update.losses)
  const [draws, setDraws] = useState(props.update && props.update.draws)
  const [complete, setComplete] = useState(0)
  const [loaded, setLoaded] = useState()
  const [sports, setSports] = useState([])
  const [tiktok, setTikTok] = useState()
  const [instagram, setInstagram] = useState()
  
  const countries = ['🇺🇸 USA', '🇬🇧 UK', '🇺🇦 Ukraine', 'Poland', 'Germany',
   'France', '🇫🇮 Finland', 'Sweden']


 useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaded(true);
      }, 1500);
    return () => {
       clearTimeout(timeout);
     };
  }, []);

 useEffect(() => {
        fetch(`/.netlify/functions/fauna-all/`, {
            body: JSON.stringify({
              index: 'sports_all'
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => setSports(response.data || []))
         .catch(error => console.log(error));
  }, [])

 function submitinfo () {
       if(props.update) {
         fetch(`/.netlify/functions/fauna-update/fighters-${props.id}`, {
              body: JSON.stringify({
                name: name,
                country: country,
                sport: sport,
                gender: gender,
                birthdate: birthdate,
                photo: photo ? photo : null,
                height: height,
                reach: reach,
                wins: wins,
                losses: losses,
                draws: draws,
                instagram: instagram,
                tiktok: tiktok
              }),
              method: 'POST'
            }).then(response => {
              return response.json()}).then(response => {
                setLoading(false);
                setComplete(1);
                fetch(`https://api.netlify.com/build_hooks/64e6357e371f9c0aff1227c8`, {
                  method: 'POST'}).then((response) => {
                  return response.json()}).then((response) => {
                    console.log(response);
                  })
                .catch(error => console.log(error));
              })
              .catch(error => (setLoading(false), console.log(error)));
       }
       else {
          fetch(`/.netlify/functions/fauna-create/fighters`, {
              body: JSON.stringify({
                name: name,
                country: country,
                sport: sport,
                gender: gender,
                birthdate: birthdate,
                photo: photo ? photo : null,
                height: height,
                reach: reach,
                wins: wins,
                losses: losses,
                draws: draws,
                instagram: instagram,
                tiktok: tiktok
              }),
              method: 'POST'
            }).then(response => {
              return response.json()}).then(response => {
                setLoading(false);
                setComplete(1);
                fetch(`https://api.netlify.com/build_hooks/64e6357e371f9c0aff1227c8`, {
                  method: 'POST'}).then((response) => {
                  return response.json()}).then((response) => {
                    console.log(response);
                  })
                .catch(error => console.log(error));
              })
              .catch(error => (setLoading(false), console.log(error)));
       }
   }

  function saveImage (image) {
    setPhoto(image);
 }

 const sportHandler = val => setSport(val)
 const countryHandler = val => setCountry(val)

 
 return (
    <>
        {complete < 1 && !loading && <div className="fighter-add">
              <Grid.Container gap={1.5}>
               <Grid xs={24}>
                 <Input scale={2} width="100%" placeholder="Fighter Name" value={name}
                  onChange={e => setName(e.target.value)} />
               </Grid>
                <Grid xs={24}>
                <Select scale={2} width="100%" placeholder="Select Sport" value={sport} onChange={sportHandler}>
                    <Select.Option value="Celebrity"><span className="sports-font">H</span> Celebrity</Select.Option>
                  {sports.map((sport) => (
                    <Select.Option value={sport[0]}><span className="sports-font">{sport[1]}</span> {sport[0]}</Select.Option>
                   ))}
                </Select>
                </Grid>
               <Grid xs={24} md={12}>
               <Input scale={2} width="100%" htmlType="number" placeholder="Height" value={height}
                onChange={e => setHeight(e.target.value)} />
               </Grid>
               <Grid xs={24} md={12}>
               <Input scale={2} width="100%" htmlType="number" placeholder="Reach" value={reach}
                onChange={e => setReach(e.target.value)} />
               </Grid>
               {(sport === 'Boxing' || sport === 'Sparring' || sport === 'MMA') && <><Grid xs={12} md={8}>
               <Input scale={2} width="100%" htmlType="number" placeholder="Wins" value={wins}
                onChange={e => setWins(e.target.value)} />
               </Grid>
               <Grid xs={12} md={8}>
               <Input scale={2} width="100%" htmlType="number" placeholder="Losses" value={losses}
                onChange={e => setLosses(e.target.value)} />
               </Grid>
               <Grid xs={24} md={8}>
               <Input scale={2} width="100%" htmlType="number" placeholder="Draws" value={draws}
                onChange={e => setDraws(e.target.value)} />
               </Grid></>}
               <Grid xs={24} md={12}>
               <Input scale={2} width="100%" htmlType="date" placeholder="Birth Date" value={birthdate}
                onChange={e => setBirthdate(e.target.value)} />
               </Grid>
               <Grid xs={24} md={12}>
               <Select scale={2} width="100%" placeholder="Select Gender" value={gender} onChange={(val) => setGender(val)}>
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                </Select>
                </Grid>
                <Grid xs={24}>
                 <Input scale={2} width="100%" placeholder="Instagram" value={instagram}
                  onChange={e => setInstagram(e.target.value)} />
               </Grid>
               <Grid xs={24}>
                 <Input scale={2} width="100%" placeholder="TikTok" value={tiktok}
                  onChange={e => setTikTok(e.target.value)} />
               </Grid>
                <Grid xs={24}>
                <Select scale={2} width="100%" placeholder="Select Country" value={country} onChange={countryHandler}>
                  {countries.map((country) => (
                    <Select.Option value={country}>{country}</Select.Option>
                   ))}
                </Select>
                </Grid>
                <Grid xs={24}>
                  {loaded && <PhotoUpload onChange={saveImage} />}
                </Grid>
                <Grid xs={24}>
                 <Button scale={2} width="100%" type="secondary" loading={loading} disabled={!name || !country || !sport || !birthdate || !photo || !height}
                   onClick={() => submitinfo()} shadow>
                    {props.update ? 'Update' : 'Add'} Fighter
                 </Button>
                </Grid>
               </Grid.Container>
              </div>}
              {complete > 0 && !loading && <div className="fighter-add">
                <h4>Success!</h4>
                <Button type="secondary" onClick={() => props.onChange()} shadow>Close</Button>
              </div>}
              {loading && <Loading />}
   </>)
}
