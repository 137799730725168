import React from "react"
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import AddFighter from '../components/AddFighter'

export default function Fighter ()  {

  return (
    <>
      <Helmet>
        <title>Add Fighter - Fanpowered</title>
        <meta name="robots" content="noindex" data-react-helmet="true" />
        <script src="https://upload-widget.cloudinary.com/global/all.js" type="text/javascript"></script>
      </Helmet>
        <AddFighter onChange={() => navigate('/fighters/')} />
      </>
  )
}