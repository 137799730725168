import React, { useState } from "react"
import { Button } from '@geist-ui/react'
import { Check } from '@geist-ui/react-icons'


export default function PhotoUpload (props) {
  
  const [hasphoto, setHasPhoto] = useState()

  var photoupload =  typeof window !== 'undefined' && window.cloudinary.createUploadWidget({
    api_key: '125168621872389',
    cloudName: 'craft-boxing-co', 
    uploadPreset: 'xoeoweok',
        sources: [
            "local",
            "camera",
            "facebook",
            "instagram"
        ],
        showAdvancedOptions: false,
        multiple: false,
        defaultSource: "local",
        styles: {
            palette: {
                window: "#ffffff",
                sourceBg: "#f4f4f5",
                windowBorder: "#90a0b3",
                tabIcon: "#000000",
                inactiveTabIcon: "#555a5f",
                menuIcons: "#555a5f",
                link: "#000000",
                action: "#000000",
                inProgress: "#000000",
                complete: "#000000",
                error: "#000000",
                textDark: "#000000",
                textLight: "#fcfffd"
            }
        }
  }, (error, result) => { 
      if (!error && result && result.event === "success") { 
        props.onChange(result.info.secure_url); 
        setHasPhoto(true);
      }
    })


    return (
      <>
        {!hasphoto && <Button scale={2} width="100%" type="secondary" onClick={() => photoupload.open()} ghost>Upload Photo</Button>}
        {hasphoto && <h6 className="uploaded"><Check />Photo Uploaded</h6>}
      </>
    )
  }
